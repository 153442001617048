import React from 'react';
import { Routes as RouterRoutes, Route, Navigate } from 'react-router-dom';
import { AUTHENTICATED_ENTRY, REDIRECT_COMPONENT_KEY } from 'configs/AppConfig';
import { protectedRoutes, publicRoutes } from 'configs/RoutesConfig';
import ProtectedRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';
import AppRoute from './AppRoute';
import { useSelector } from 'react-redux';
import { ADMIN_TYPE, SUBADMIN_TYPE } from 'constants/AuthConstant';

const Routes = () => {
	const { type, roles } = useSelector((state) => state.auth);
	return (
		<RouterRoutes>
			<Route path="/" element={<ProtectedRoute />}>
				{/* redirects the route from home ('/') to default page 'AUTHENTICATED_ENTRY' */}
				<Route path="/" element={<Navigate replace to={AUTHENTICATED_ENTRY} />} />
				{protectedRoutes.map((route, index) => {
					if (
						route.isPage ||
						type === ADMIN_TYPE ||
						route.key === REDIRECT_COMPONENT_KEY ||
						((type === SUBADMIN_TYPE) &&
							roles[route.key]?.view)
					) {
						return (
							<Route
								key={route.key + index}
								path={route.path}
								element={
									<AppRoute
										routeKey={route.key}
										component={route.component}
										{...route.meta}
									/>
								}
							/>
						)
					}
				})}
				{/* redirects the url to '/' when the route for a particular url is not present */}
				<Route path="*" element={<Navigate to="/" replace />} />
			</Route>
			<Route path="/" element={<PublicRoute />}>
				{publicRoutes.map(route => {
					return (
						<Route
							key={route.path}
							path={route.path}
							element={
								<AppRoute
									routeKey={route.key}
									component={route.component}
									{...route.meta}
								/>
							}
						/>
					)
				})}
			</Route>
		</RouterRoutes>
	)
}

export default Routes